<template>
  <!-- 会议信息管理 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
      <!-- <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div> -->
    </base-form>
    <!-- 新增会议信息 -->
    <div class="main-page-table__header">
      <base-button
        @click="addshow"
        label="新增"
        icon="el-icon-plus"
      ></base-button>
    </div>
    <!-- 数据表格 -->
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <icon-button
          @click="edit(scope.row.keyId)"
          content="编辑"
          icon="iconfont iconbianji3"
        />
        <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row.keyId)"
        />
      </template>
    </base-table>
    <!-- 新增修改弹窗 -->
  <add-and-edit-mentting :visible.sync="visible" :dialogTitle="dialogTitle" :findById.sync="findById" :showInfo.sync='showInfo' @handel='handleFilter'></add-and-edit-mentting>
  </div>
</template>
<script>
import baseForm from '@/components/common/base-form/base-form.vue'
import { financingConfig, columnsConfig } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import AddAndEditMentting from './components/add-and-edit-mentting.vue'
import { meetApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton, BaseButton, AddAndEditMentting },
  data () {
    return {
      showInfo: false,
      fold: false,
      visible: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      },
      disabled: false,
      tableData: [],
      gysData: [],
      dialogTitle: '',
      findById: ''
    }
  },
  computed: {
    api () {
      return meetApi
    },
    formConfig () {
      return financingConfig(this)
    },
    columns () {
      return columnsConfig(this)
    }
  },
  mounted () {
    this.handleFilter()
  },
  activated () {
    this.handleFilter()
  },
  methods: {
    // 查看详情
    info (data) {
      this.showInfo = true
      this.findById = data
      this.dialogTitle = '会议信息详情'
      this.visible = true
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      }
    },
    // 编辑
    edit (data) {
      this.findById = data
      this.dialogTitle = '编辑会议信息'
      this.visible = true
    },
    // 新增
    addshow () {
      this.dialogTitle = '新增会议信息'
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.addbtn {
  /deep/.el-icon-plus {
    font-size: 20px;
  }
  button {
    height: 34px;
    padding: 0;
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
<style lang="scss" scoped>

</style>
